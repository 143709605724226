import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { getEntity, reset } from '../../../shared/reducers/model/player-profile.reducer'
import { createEntity, updateEntity } from '../../../shared/reducers/model/extended/player-profile.extended.reducer'
import { getEntities as getCountries } from '../../../shared/reducers/model/country.reducer'
import { getEntities as getPlayerCategories } from '../../../shared/reducers/model/player-category.reducer'
import { getEntities as getNationalities } from '../../../shared/reducers/model/nationality.reducer'
import { getEntities as getFederations } from '../../../shared/reducers/model/federation.reducer'
import { getPersonalBalanceHistoryForPlayerProfile } from '../../../shared/reducers/model/extended/personal-balance-history.extended.reducer'
import { createEntity as createPersonalBalanceHistory } from '../../../shared/reducers/model/personal-balance-history.reducer'
import { defaultValue as defaultPersonalBalanceHistory, IPersonalBalanceHistory } from '../../../shared/model/personal-balance-history.model'

import { Input } from 'antd/es'
import { Button, Col, DatePicker, Divider, Form, InputNumber, List, message, Modal, Row, Select, Space, Switch } from 'antd'
import { GlobalOutlined, MailOutlined } from '@ant-design/icons'

import moment from 'moment'
import TextArea from 'antd/es/input/TextArea'
import Text from 'antd/es/typography/Text'
import Paragraph from 'antd/es/typography/Paragraph'
import { APP_LOCAL_DATETIME_FORMAT_EU } from '../../../redux/settings/constants'

export interface IPlayerProfileProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PlayerProfileUpdate = (props: IPlayerProfileProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const [stayOnPage, setStayOnPage] = useState(false)
  const {
    entity,
    onSetPage,
    getPageData,
    updateSuccess,
    loading,
    countriesLoading,
    federationsLoading,
    nationalitiesLoading,
    personalBalanceHistoryLoading,
    playerCategoriesLoading,
    countries,
    federations,
    nationalities,
    personalBalanceHistories,
    playerCategories,
  } = props
  const [submitLoading, setLoadingSubmit] = useState(false)

  const pageData: IPageData = {
    title: `Player Profile Entry - ${!isNew ? `Profile: ${entity.firstName} ${entity.lastName} [${entity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Player Profiles',
        route: 'management/website/player/profile',
      },
      {
        title: !isNew ? `Profile: ${entity.firstName} ${entity.lastName}` : 'Create new Player Profile Entry',
      },
    ],
  }

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id)
    } else {
      props.reset()
    }
    props.getPlayerCategories(0, 99, 'name')
    props.getCountries(0, 999, 'name')
    props.getNationalities(0, 999, 'name')
    props.getFederations(0, 999, 'name')
    onSetPage(pageData)
    // Next Line is preventing Chrome and Firefox autofill/autocomplete feature nightmare
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopDamnAutocomplete')
      //you can put any value but NOT "off" or "false" because they DO NOT works
    })
  }, [])

  useEffect(() => {
    if (!loading && !isNew && entity?.id) {
      let formValues = {
        ...entity,
        birthday: moment(entity.birthday, 'YYYY-MM-DD'),
      }
      setFieldsValue(formValues)
      props.getPersonalBalanceHistoryForPlayerProfile(entity.id)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [loading])

  useEffect(() => {
    if (updateSuccess && !stayOnPage) {
      props.history.push('/app/management/player/profile')
    }
    if (stayOnPage) {
      setTimeout(() => setStayOnPage(false), 500)
    }
  }, [updateSuccess])

  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const [availablePlayerCategories, setAvailablePlayerCategories] = useState([])

  useEffect(() => {
    if (!playerCategoriesLoading) {
      setAvailablePlayerCategories(playerCategories.map(category => ({
        value: category.id,
        label: category.name,
      })))
    }
  }, [playerCategoriesLoading])

  const [availableCountries, setAvailableCountries] = useState([])

  useEffect(() => {
    if (!countriesLoading) {
      setAvailableCountries(countries.map(country => ({
        value: country.id,
        label: country.name,
      })))
    }
  }, [countriesLoading])

  const [availableNationalities, setAvailableNationalities] = useState([])

  useEffect(() => {
    if (!nationalitiesLoading) {
      setAvailableNationalities(nationalities.map(nationality => ({
        value: nationality.id,
        label: nationality.name,
      })))
    }
  }, [nationalitiesLoading])

  const [availableFederations, setAvailableFederations] = useState([])

  useEffect(() => {
    if (!federationsLoading) {
      setAvailableFederations(federations.map(federation => ({
        value: federation.id,
        label: federation.name,
      })))
    }
  }, [federationsLoading])

  const [form] = Form.useForm()
  const [personalBalanceForm] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...entity,
          ...values,
          teamProfile: false,
          birthday: values.birthday.format('YYYY-MM-DD'),
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...entity,
        birthday: moment(entity.birthday, 'YYYY-MM-DD'),
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleSendMail = (fieldName: string) => {
    if (fieldName) {
      const value = getFieldValue(fieldName)
      if (value) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          window.location.href = 'mailto:' + value
        } else {
          message.warn('This is not a valid mail address')
        }
      }
    }
  }

  const handleGotoWebsite = (fieldname: string) => {
    if (fieldname) {
      const value = getFieldValue(fieldname)
      if (value) {
        if (/^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(value)) {
          window.open(value, '_blank')
        } else if (/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g.test(value)) {
          window.open('http://' + value, '_blank')
        } else {
          message.warn('This is not a valid website address')

        }
      }
    }
  }

  const onNameChange = () => {
    const lastName = form.getFieldValue('lastName')
    const firstName = form.getFieldValue('firstName')
    if (typeof lastName === 'string' && typeof firstName === 'string')
      form.setFieldsValue({
        lastName: lastName.trim(),
        firstNAme: firstName.trim(),
        displayName: `${lastName.trim().toUpperCase()} ${firstName.trim()}`,
        initials: `${firstName.trim().charAt(0).toUpperCase()}.${lastName.trim().charAt(0).toUpperCase()}.`,
      })
  }

  return (
    <Form form={form} id={'updatePlayerProfile'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
          autoComplete={'off'}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleFormOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12}>
          {!isNew && entity.registrationDate ? (
            <Paragraph style={{ textAlign: 'right' }}>
              <Text>RegistrationDate: {entity.registrationDate}</Text>
            </Paragraph>
          ) : null}
          <Form.Item name="enabled" label="Player Profile active" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} valuePropName="checked">
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="lastName" label={'Lastname'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: true, message: 'Please enter a lastname' }, { max: 255 }]}>
            <Input onBlur={onNameChange}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="displayName" label={'Display Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     help="This field automatically updates on name changes"
                     rules={[{ required: true, message: 'Please enter a display name' }, { max: 255 }]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="firstName" label={'Firstname'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: true, message: 'Please enter a firstname' }, { max: 255 }]}>
            <Input onBlur={onNameChange}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="initials" label={'Initials'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     help="This field automatically updates on name changes"
                     rules={[{ required: true, message: 'Please enter a display name' }, { max: 255 }]}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nickName" label={'Nickname'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="birthday" label={'Birthday'} labelCol={{ span: 8 }}
                     rules={[{ required: true, message: 'Please enter the date of birth' }]}>
            <DatePicker/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="sex" label={'Sex'} labelCol={{ span: 8 }}>
            <Select>
              <Select.Option value={null}>-</Select.Option>
              <Select.Option value={'MALE'}>male</Select.Option>
              <Select.Option value={'FEMALE'}>female</Select.Option>
              <Select.Option value={'DIVERS'}>divers</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="nationalityId" label="Nationality" labelCol={{ span: 8 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.nationalitiesLoading}
              options={availableNationalities}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="playerCategoryId" label="Player Category" labelCol={{ span: 8 }}>
            <Select
              loading={props.playerCategoriesLoading}
              options={availablePlayerCategories}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="representsCountryId" label="Represents" labelCol={{ span: 8 }}
                     rules={[{ required: true, message: 'Represented country is required' }]}
          >
            <Select
                allowClear
                showSearch
                optionFilterProp="label"
                loading={props.countriesLoading}
                options={availableCountries}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12}>
          <Form.Item name="blockedForRegistrations" label="Tournament registration locked" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} valuePropName="checked"
                     help="If enabled, the player cannot register to tournaments">
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="blockedForRegistrationsPlayerInfo" label="Player Info for lock" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     help="This message is displayed to a player when he wants to register for tournaments but the registration is blocked."
          >
            <TextArea rows={6}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="blockedForRegistrationsAdminInfo" label="Admin Info for lock" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     help="Internal note for locking."
          >
            <TextArea rows={6}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <Form.Item name="federationPoolId" label="Federation Pool" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.federationsLoading}
              options={availableFederations}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="federationSnookerId" label="Federation Snooker" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.federationsLoading}
              options={availableFederations}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="federationCaromId" label="Federation Carom" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.federationsLoading}
              options={availableFederations}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="street" label={'Street'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input autoComplete={'street'}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="postcode" label={'Postcode'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input autoComplete={'postcode'}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="city" label={'City'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input autoComplete={'city'}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="countryId" label="Country" labelCol={{ span: 8 }}
                     rules={[{ required: true, message: 'Country is required' }]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.countriesLoading}
              options={availableCountries}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="state" label={'State'} labelCol={{ span: 8 }}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="telephone" label={'Telephone'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="telefax" label={'Telefax'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="mobile" label={'Mobile'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="email" label={'Email'} labelCol={{ span: 8 }}
                     rules={[{ required: true, message: 'A main email address is required' }]}
          >
            <Input addonAfter={<MailOutlined onClick={() => handleSendMail('email')}/>}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="email2" label={'Email 2'} labelCol={{ span: 8 }}>
            <Input addonAfter={<MailOutlined onClick={() => handleSendMail('email2')}/>}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="websitePrivate" label={'Website'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input addonAfter={<GlobalOutlined onClick={() => handleGotoWebsite('websitePrivate')}/>}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="bankAccountName" label={'Bank Account Name'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="bankName" label={'Bank Name'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bankAddress" label={'Bank Address'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bankIban" label={'Bank IBAN'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bankBic" label={'Bank BIC'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="bankSwift" label={'Bank Swift'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row>
        <Col span={12}>
          <Form.Item name="personalBalance" label={'Personal-Balance'} labelCol={{ span: 8 }}>
            € {entity.personalBalance}
          </Form.Item>
        </Col>
        <Col span={12}>
          <List size={'small'} pagination={{ hideOnSinglePage: true }} dataSource={personalBalanceHistories.flat()} loading={personalBalanceHistoryLoading}
                header={<div>
                  <Space size={'small'} style={{ width: '100%', justifyContent: 'end' }} align={'baseline'}>
                    <Button size={'small'} onClick={() => {
                      setShowPaymentModal(true)
                    }} ghost type="primary">
                      New payment entry
                    </Button>
                  </Space>
                </div>}
                renderItem={(personalBalance) => <List.Item>
                  <List.Item.Meta
                    title={`${moment(personalBalance.entryDate).format(APP_LOCAL_DATETIME_FORMAT_EU)}: € ${personalBalance.amount}`}
                    description={personalBalance.notice}
                  />
                </List.Item>}/>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item name="equipment" label={'Equipment'}>
            <TextArea rows={10}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="sponsor" label={'Sponsor'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="websiteSponsor" label={'Sponsors Website'} labelCol={{ span: 8 }}
                     rules={[{ max: 255 }]}
          >
            <Input addonAfter={<GlobalOutlined onClick={() => handleGotoWebsite('websiteSponsor')}/>}/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Space>
            <Button disabled={true}>Tournament-List</Button>
            <Button disabled={true}>Register for Tournament</Button>
            <Button disabled={true}>Create User-Login</Button>
            {/*<Button danger disabled={true}>Delete Player</Button>*/}
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Link to={'../profile'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Modal visible={showPaymentModal} title={'Payment entry'}
             onCancel={() => setShowPaymentModal(false)}
             okType={'primary'} okText={'Save'} okButtonProps={{ loading: personalBalanceHistoryLoading }} onOk={() => {
        const values = personalBalanceForm.getFieldsValue()
        props.createPersonalBalanceHistory({
          ...defaultPersonalBalanceHistory,
          playerProfileId: entity.id,
          entryDate: moment(),
          ...values,
        })
        setStayOnPage(true)
        props.updateEntity({ ...entity, personalBalance: entity.personalBalance + values.amount })
        setShowPaymentModal(false)
      }}
      >
        <Form form={personalBalanceForm}
              layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item name={'amount'} label={'Amount'} help={'To subtract Money, enter negative amount'} required>
                <InputNumber required/>
              </Form.Item>
              <Form.Item name={'notice'} label={'Notice'} required>
                <Input required/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entity: storeState.playerProfile.entity,
  loading: storeState.playerProfile.loading,
  updating: storeState.playerProfile.updating,
  updateSuccess: storeState.playerProfile.updateSuccess,
  playerCategories: storeState.playerCategory.entities,
  playerCategoriesLoading: storeState.playerCategory.loading,
  countries: storeState.country.entities,
  countriesLoading: storeState.country.loading,
  nationalities: storeState.nationality.entities,
  nationalitiesLoading: storeState.nationality.loading,
  federations: storeState.federation.entities,
  federationsLoading: storeState.federation.loading,
  personalBalanceHistories: storeState.personalBalanceHistory.entities,
  personalBalanceHistoryLoading: storeState.personalBalanceHistory.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getCountries,
  getPlayerCategories,
  getNationalities,
  getFederations,
  getPersonalBalanceHistoryForPlayerProfile,
  createPersonalBalanceHistory,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerProfileUpdate))