import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Switch } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { IAppState } from '../../redux/store'
import { createEntity, getEntity, reset, setBlob, updateEntity } from '../../shared/reducers/model/extended/tournament.extended.reducer'
import { defaultValue } from '../../shared/model/extended/tournament.extended.model'
import { getEntities as getTournamentCategories, getEntity as getTournamentCategory } from '../../shared/reducers/model/tournament-category.reducer'
import { getEntities as getPlayerCategories } from '../../shared/reducers/model/player-category.reducer'
import { getEntities as getChartTemplates } from '../../shared/reducers/model/chart-template.reducer'
import { getEntities as getDisciplines } from '../../shared/reducers/model/discipline.reducer'
import { getEntities as getVenues } from '../../shared/reducers/model/venue.reducer'
import { getEntitiesWithoutArchive as getEvents } from '../../shared/reducers/model/event.reducer'
// import { getEntities as getOverallRankings } from '../../shared/reducers/model/overall-ranking.reducer'
import { getEntities as getRankings } from '../../shared/reducers/model/ranking.reducer'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IPageData, IPageProps } from '../../interfaces/page-data'
import { BreakRules } from '../../shared/model/enumerations/break-rules.model'
import { RankingCategory } from '../../shared/model/enumerations/ranking-category.model'
import moment from 'moment'
import { APP_LOCAL_DATE_FORMAT_EU } from '../../redux/settings/constants'

import './tournament-edit.modal.scss'
import { EventType } from '../../shared/model/enumerations/event-type.model'
import { TournamentCategoryType } from '../../shared/model/enumerations/tournament-category-type.model'
import { mapIdList } from '../../utils/entity-utils'
import EventPosterUploadModal from '../events/event-poster.upload'

export interface ITournamentsUpcomingEditModalProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const TournamentsUpcomingEditModal: React.FC<ITournamentsUpcomingEditModalProps> = (props: ITournamentsUpcomingEditModalProps) => {

  const [submitLoading, setLoadingSubmit] = useState(false)

  const [isEventPosterUploadModalVisible, showEventPosterUploadModal] = useState(false)

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const {
    tournamentEntity,
    loading,
    updating,
    updateSuccess,
    errorMessage,
    tournamentCategories,
    tournamentCategoriesLoading,
    playerCategories,
    playerCategoriesLoading,
    chartTemplates,
    chartTemplatesLoading,
    disciplines,
    disciplinesLoading,
    venues,
    venuesLoading,
    events,
    eventsLoading,
    // overallRankings,
    // overallRankingsLoading,
    rankings,
    rankingsLoading,
  } = props
  const { onSetPage } = props

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const { description } = tournamentEntity

  const [eventType, setEventType] = useState<EventType>(null)
  const [minParticipants, setMinParticipants] = useState(0)
  const [maxParticipants, setMaxParticipants] = useState(0)

  useEffect(() => {
    props.getTournamentCategories()
    props.getPlayerCategories(0, 99, 'name,asc')
    props.getChartTemplates(0, 99, 'maxParticipants,numberOfQualifierStage1,desc')
    props.getDisciplines()
    props.getVenues()
    props.getEvents()
    // props.getOverallRankings()
    props.getRankings()
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    return () => {
      props.reset()
    }
  }, [])

  const pageData: IPageData = {
    title: 'Tournaments',
    loaded: false,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'matches',
      },
      {
        title: 'Tournament',
      },
    ],
  }

  useEffect(() => {
    if (tournamentEntity && tournamentEntity?.id) {
      let formValues = {
        ...tournamentEntity,
        tournamentStartDate: tournamentEntity.tournamentStartDate ? moment(tournamentEntity.tournamentStartDate, 'YYYY-MM-DD') : null,
        tournamentEndDate: tournamentEntity.tournamentEndDate ? moment(tournamentEntity.tournamentEndDate, 'YYYY-MM-DD') : null,
        tournamentDates: [tournamentEntity.tournamentStartDate ? moment(tournamentEntity.tournamentStartDate, 'YYYY-MM-DD') : null, tournamentEntity.tournamentEndDate ? moment(tournamentEntity.tournamentEndDate, 'YYYY-MM-DD') : null],
        entryFee1Until: tournamentEntity.entryFee1Until ? moment(tournamentEntity.entryFee1Until, 'YYYY-MM-DD') : null,
        entryFee2Until: tournamentEntity.entryFee2Until ? moment(tournamentEntity.entryFee2Until, 'YYYY-MM-DD') : null,
        entryFee3Until: tournamentEntity.entryFee3Until ? moment(tournamentEntity.entryFee3Until, 'YYYY-MM-DD') : null,
        playerCategories: tournamentEntity.playerCategories ? tournamentEntity.playerCategories.map(value => value.id) : null,
      }
      onChangeChartTemplate(tournamentEntity.chartTemplateId)
      onTournamentCategoryChange(tournamentEntity.tournamentCategoryId)
      checkEventType(tournamentEntity.eventId)
      setNumberOfTeamMatches(tournamentEntity.numberOfTeamMatches)
      setFieldsValue(formValues)
    } else {
      setFieldsValue(defaultValue)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [tournamentEntity])

  useEffect(() => {
    if (updateSuccess && !isEventPosterUploadModalVisible) {
      message.success('Record successfully saved')
      setLoadingSubmit(false)
      handleOnClose()
    } else if (errorMessage) {
      console.log('errorMessage', errorMessage)
      message.error('Record could not be saved')
      setLoadingSubmit(false)
    }
    if (isEventPosterUploadModalVisible)
      showEventPosterUploadModal(false)
  }, [updateSuccess, errorMessage])

  const handleOnClose = () => {
    props.history.push('/app/tournaments/upcoming')
  }

  const handleOnSubmit = () => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...defaultValue,
          ...tournamentEntity,
          ...values,
          playerCategories: values.playerCategories ? mapIdList(values.playerCategories) : null,
          tournamentStartDate: (values.tournamentDates && values.tournamentDates[0]) ? values.tournamentDates[0].format('YYYY-MM-DD') : null,
          tournamentEndDate: (values.tournamentDates && values.tournamentDates[1]) ? values.tournamentDates[1].format('YYYY-MM-DD') : null,
          entryFee1Until: values.entryFee1Until ? values.entryFee1Until.format('YYYY-MM-DD') : null,
          entryFee2Until: values.entryFee2Until ? values.entryFee2Until.format('YYYY-MM-DD') : null,
          entryFee3Until: values.entryFee3Until ? values.entryFee3Until.format('YYYY-MM-DD') : null,
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
      })
      .catch(reason => {
        //console.log('Validation failed:', reason)
        message.error(`Record could not be saved\n${reason.errorFields ? JSON.stringify(reason.errorFields) : ''}`)
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const onEditorChange = (value, delta, source, editor) => {
    setFieldsValue({ description: value })
  }

  const checkEventType = (eventId) => {
    console.log('eventId:', eventId)
    if (eventId && eventId > 0) {
      const event = props.events.find(value => value.id === eventId)
      setEventType((event && event.eventType) ? EventType[event.eventType] : null)
      if (isNew) {
        setFieldsValue({
          tournamentStartDate: moment(event.eventStartDate, 'YYYY-MM-DD'),
          tournamentEndDate: moment(event.eventEndDate, 'YYYY-MM-DD'),
          tournamentDates: [moment(event.eventStartDate, 'YYYY-MM-DD'), moment(event.eventEndDate, 'YYYY-MM-DD')],
          entryFee1Until: moment(event.eventStartDate, 'YYYY-MM-DD'),
        })
      }
    }
  }

  const onChangeChartTemplate = (chartTemplateId) => {
    console.log('chartTemplateId:', chartTemplateId)
    if (chartTemplateId && chartTemplateId > 0 && props.chartTemplates && props.chartTemplates.length > 0) {
      const chartTemplate = props.chartTemplates.find(value => value.id === chartTemplateId)
      setMinParticipants(chartTemplate.minParticipants)
      setMaxParticipants(chartTemplate.maxParticipants)
      setFieldsValue({ entryLimit: chartTemplate.maxParticipants })
    }
  }

  useEffect(() => {
    if (!tournamentEntity.entryFee1 || tournamentEntity.entryFee1 == 0)
      setFieldsValue({
        entryFee1: eventType === EventType.EURO_TOUR ? 150 : 0,
      })
    if (!tournamentEntity.entryFee2 || tournamentEntity.entryFee2 == 0)
      setFieldsValue({
        entryFee2: 0,
      })
    if (!tournamentEntity.entryFee3 || tournamentEntity.entryFee3 == 0)
      setFieldsValue({
        entryFee3: 0,
      })
    if (!tournamentEntity.entryFeeReduced || tournamentEntity.entryFeeReduced == 0)
      setFieldsValue({
        entryFeeReduced: eventType === EventType.EURO_TOUR ? 100 : 0,
      })
    if (!tournamentEntity.entryFeeReduced2 || tournamentEntity.entryFeeReduced2 == 0)
      setFieldsValue({
        entryFeeReduced2: eventType === EventType.EURO_TOUR ? 100 : 0,
      })
  }, [eventType])

  useEffect(() => {
    if (props.chartTemplates && props.chartTemplates.length > 0 && tournamentEntity && tournamentEntity?.chartTemplateId) {
      const chartTemplate = props.chartTemplates.find(value => value.id === tournamentEntity.chartTemplateId)
      setMinParticipants(chartTemplate.minParticipants)
      setMaxParticipants(chartTemplate.maxParticipants)
    }
  }, [props.chartTemplates])

  const onTournamentCategoryChange = (tournamentCategoryId) => {
    console.log('Tournament category id: ', tournamentCategoryId)
    props.getTournamentCategory(tournamentCategoryId)
  }

  const onChangeRankingCategory = (rankingCategory) => {
    console.log('Ranking category: ', rankingCategory)

    switch (RankingCategory[rankingCategory]) {
      case RankingCategory.ETM:
        setFieldsValue({
          tournamentCategoryId: 7,
        })
        break
      case RankingCategory.ETW:
        setFieldsValue({
          tournamentCategoryId: 13,
        })
        break
    }
  }

  const [showTeamFields, setShowTeamFields] = useState(false)
  useEffect(() => {
    console.log(!props.tournamentCategoriesLoading, !!props.tournamentCategory, !!props.tournamentCategory.id, props.tournamentCategory.id)
    if (!props.tournamentCategoriesLoading && props.tournamentCategory && props.tournamentCategory.id) {
      setShowTeamFields(props.tournamentCategory.type == TournamentCategoryType.TEAM)
    } else {
      setShowTeamFields(false)
    }
  }, [props.tournamentCategory, props.tournamentCategoriesLoading])

  const [numberOfTeamMatches, setNumberOfTeamMatches] = useState<number>(tournamentEntity?.numberOfTeamMatches || 0)

  return <>
    <Modal visible={true}
           width={900}
           title={`${isNew ? 'Create new' : 'Edit'} tournament`}
           destroyOnClose={true}
           footer={[!isNew && eventType === EventType.EURO_TOUR ?
             <Button onClick={() => showEventPosterUploadModal(true)}>{props.tournamentEntity.eventPosterId ? 'Replace' : 'Add'} Event Poster</Button>
             : null,
             <Button onClick={handleOnClose}>Cancel</Button>,
             <Button onClick={handleOnSubmit} loading={submitLoading}>Save</Button>,
           ]}
    >
      <Form form={form} id="editTournamentForm" layout={'horizontal'}
            labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item hidden name="maxParticipants"/>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item name="eventId" label="Event" required rules={[
              { required: true },
            ]}>
              <Select loading={eventsLoading} showSearch optionFilterProp="label"
                      onChange={checkEventType}
                      options={events.map(event => ({ key: `event${event.id}`, value: event.id, label: event.name }))}/>
            </Form.Item>
            <Form.Item name="name" label="Name" rules={[
              { required: true },
            ]}>
              <Input type="text" required/>
            </Form.Item>
            <Form.Item name="mnemonic" label="Mnemonic" rules={[
              { required: true },
            ]}>
              <Input type="text" required/>
            </Form.Item>
            <Form.Item name="titleForOverallRanking" label="Title Overall Rank." rules={[
              { required: eventType === EventType.EURO_TOUR },
            ]}>
              <Input type="text" required/>
            </Form.Item>
            <Form.Item name="tournamentDates" label={'From/To'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} rules={[{ required: eventType === EventType.EURO_TOUR }]}>
              <DatePicker.RangePicker format={APP_LOCAL_DATE_FORMAT_EU}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="entryFee1" label="Entry fee 1"
                       rules={[{ required: eventType === EventType.EURO_TOUR, type: 'number' }]}
                       labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
            >
              <InputNumber
                min={0}
              />
            </Form.Item>
            <Form.Item name="entryFee2" label="Entry fee 2" rules={[{ type: 'number' }]} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
              <InputNumber
                min={0}
              />
            </Form.Item>
            <Form.Item name="entryFee3" label="Entry fee 3" rules={[{ type: 'number' }]} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
              <InputNumber
                min={0}
              />
            </Form.Item>
            <Form.Item name="entryFeeReduced" label="Women" rules={[{ type: 'number' }]} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
              <InputNumber
                min={0}
              />
            </Form.Item>
            <Form.Item name="entryFeeReduced2" label="U18" rules={[{ type: 'number' }]} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
              <InputNumber
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="entryFee1Until" label={'until'} rules={[{ required: eventType === EventType.EURO_TOUR }]}>
              <DatePicker format={APP_LOCAL_DATE_FORMAT_EU}/>
            </Form.Item>
            <Form.Item name="entryFee2Until" label={'until'}>
              <DatePicker format={APP_LOCAL_DATE_FORMAT_EU}/>
            </Form.Item>
            <Form.Item name="entryFee3Until" label={'until'}>
              <DatePicker format={APP_LOCAL_DATE_FORMAT_EU}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rankingCategory" label={'Ranking Category'} rules={[{ required: true }]}>
              <Select onChange={onChangeRankingCategory}>
                {Object.keys(RankingCategory).map(category => (
                  <Select.Option key={`type${category.toString()}`}
                                 value={category.toString()}>{RankingCategory[category]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="tournamentCategoryId" label={'Classification'} hidden={eventType !== EventType.EUROPEAN_CHAMPIONSHIP}>
              <Select allowClear loading={tournamentCategoriesLoading}
                      options={tournamentCategories.map(category => ({
                        key: `category${category.id}`,
                        value: category.id,
                        label: category.name,
                      }))}
                      onChange={onTournamentCategoryChange}
              />
            </Form.Item>
            {eventType === EventType.EURO_TOUR ?
              <>
                <Form.Item name="playerCategories" label={'Allowed ages'}>
                  <Select mode={'multiple'}
                          allowClear
                          loading={playerCategoriesLoading}
                          options={playerCategories.map(category => ({
                            key: `pCategory${category.id}`,
                            value: category.id,
                            label: category.name,
                          }))}
                  />
                </Form.Item>
              </>
              : null
            }

            {showTeamFields ?
              <Form.Item name="numberOfTeamMatches" label={'No. team matches'}
                         rules={[{ type: 'number', min: 0, max: 5 }]}
              >
                <InputNumber min={0} max={5} onChange={value => setNumberOfTeamMatches(Number(value))}/>
              </Form.Item>
              :
              <Form.Item name="numberOfTeamMatches" hidden>
                <Input type={'hidden'} value={0}/>
              </Form.Item>
            }
          </Col>
          <Col span={12}>
            <Form.Item name="chartTemplateId" label={'Chart-Template'}>
              <Select allowClear loading={chartTemplatesLoading}
                      options={chartTemplates.map(template => ({
                        key: `template${template.id}`,
                        value: template.id,
                        label: template.name,
                      }))}
                      onChange={onChangeChartTemplate}
              />
            </Form.Item>
            <Form.Item name="entryLimit" label={'Max. Participants'}
                       rules={[{ type: 'number', min: minParticipants, max: maxParticipants }]}
            >
              <InputNumber min={minParticipants} max={maxParticipants}/>
            </Form.Item>
            <Form.Item name="numberOfPlayerSeeded" label={'No. seeded players'}
                       rules={[{ type: 'number', min: 0, max: maxParticipants }]}
            >
              <InputNumber min={0} max={maxParticipants}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" hidden>
              <Input type="text"/>
            </Form.Item>
            <Form.Item name="description" label="Description" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
              <ReactQuill id="descriptionEditor" theme={'snow'} value={description}
                          onChange={onEditorChange}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="autoApproveMatches" label="Auto approve" help={'Auto Approve finished Matches.'}
                       valuePropName={'checked'}>
              <Switch/>
            </Form.Item>

            <Form.Item name="withUniformControl" label="Uniform-Control" valuePropName="checked">
              <Switch/>
            </Form.Item>
            {/*
          <Form.Item name="assignRankingId" label={'Points/Prizepool'}>
            <Select allowClear loading={rankingsLoading}
                    options={rankings.map(ranking => ({
                      key: `ranking${ranking.id}`,
                      value: ranking.id,
                      label: ranking.name,
                    }))}
                    onChange={(value, option) => console.log(value, option)}
            />
          </Form.Item>
*/}
            {showTeamFields && numberOfTeamMatches > 0 ?
              <Form.Item label={'Team Match 1'}>
                <Input.Group>
                  <Form.Item noStyle name="teamDiscipline1Id">
                    <Select loading={disciplinesLoading}
                            options={disciplines.map(discipline => ({
                              key: `discipline${discipline.id}`,
                              value: discipline.id,
                              label: discipline.name,
                            }))}
                            placeholder="Discipline"
                            style={{ width: '65%' }}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="teamRaceTo1">
                    <InputNumber min={0} max={999} placeholder="Race-To"
                                 style={{ width: '35%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              : null
            }
            {showTeamFields && numberOfTeamMatches > 1 ?
              <Form.Item label={'Team Match 1'}>
                <Input.Group>
                  <Form.Item noStyle name="teamDiscipline2Id">
                    <Select loading={disciplinesLoading}
                            options={disciplines.map(discipline => ({
                              key: `discipline${discipline.id}`,
                              value: discipline.id,
                              label: discipline.name,
                            }))}
                            placeholder="Discipline"
                            style={{ width: '65%' }}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="teamRaceTo2">
                    <InputNumber min={0} max={999} placeholder="Race-To"
                                 style={{ width: '35%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              : null
            }
            {showTeamFields && numberOfTeamMatches > 2 ?
              <Form.Item label={'Team Match 3'}>
                <Input.Group>
                  <Form.Item noStyle name="teamDiscipline3Id">
                    <Select loading={disciplinesLoading}
                            options={disciplines.map(discipline => ({
                              key: `discipline${discipline.id}`,
                              value: discipline.id,
                              label: discipline.name,
                            }))}
                            placeholder="Discipline"
                            style={{ width: '65%' }}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="teamRaceTo3">
                    <InputNumber min={0} max={999} placeholder="Race-To"
                                 style={{ width: '35%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              : null
            }
            {showTeamFields && numberOfTeamMatches > 3 ?
              <Form.Item label={'Team Match 4'}>
                <Input.Group>
                  <Form.Item noStyle name="teamDiscipline4Id">
                    <Select loading={disciplinesLoading}
                            options={disciplines.map(discipline => ({
                              key: `discipline${discipline.id}`,
                              value: discipline.id,
                              label: discipline.name,
                            }))}
                            placeholder="Discipline"
                            style={{ width: '65%' }}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="teamRaceTo4">
                    <InputNumber min={0} max={999} placeholder="Race-To"
                                 style={{ width: '35%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              : null
            }
            {showTeamFields && numberOfTeamMatches > 4 ?
              <Form.Item label={'Team Match 5'}>
                <Input.Group>
                  <Form.Item noStyle name="teamDiscipline5Id">
                    <Select loading={disciplinesLoading}
                            options={disciplines.map(discipline => ({
                              key: `discipline${discipline.id}`,
                              value: discipline.id,
                              label: discipline.name,
                            }))}
                            placeholder="Discipline"
                            style={{ width: '65%' }}
                    />
                  </Form.Item>
                  <Form.Item noStyle name="teamRaceTo5">
                    <InputNumber min={0} max={999} placeholder="Race-To"
                                 style={{ width: '35%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              : null
            }
          </Col>
          <Col span={12}>
            <Form.Item name="disciplineId" label={'Discipline'} rules={[{ required: true }]}>
              <Select loading={disciplinesLoading}
                      options={disciplines.map(discipline => ({
                        key: `discipline${discipline.id}`,
                        value: discipline.id,
                        label: discipline.name,
                      }))}
              />
            </Form.Item>
            <Form.Item name="breakRule" label="Break-rule">
              <Select>
                {Object.keys(BreakRules).map(breakRule => (
                  <Select.Option key={`rule${breakRule.toString()}`}
                                 value={breakRule.toString()}>{breakRule.toString()}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="raceTo" label="Race-To" rules={[
              { required: true },
            ]}>
              <Input type={'number'} min={1}/>
            </Form.Item>
            <Form.Item name="numberOfTimeouts" label="Time-Outs" help={'Time-Outs per Match'} rules={[
              { required: true },
            ]}>
              <Input type={'number'} min={1}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    <EventPosterUploadModal visible={isEventPosterUploadModalVisible}
                            eventPosterId={props.tournamentEntity.eventPosterId}
                            onOk={(eventPosterId) => {
                              props.updateEntity({
                                ...props.tournamentEntity,
                                eventPosterId,
                              })
                            }}
                            onCancel={() => showEventPosterUploadModal(false)}
    />
  </>
}

const mapStateToProps = (storeState: IAppState) => ({
  tournamentEntity: storeState.tournamentExtended.entity,
  loading: storeState.tournamentExtended.loading,
  updating: storeState.tournamentExtended.updating,
  errorMessage: storeState.tournamentExtended.errorMessage,
  updateSuccess: storeState.tournamentExtended.updateSuccess,
  tournamentCategory: storeState.tournamentCategory.entity,
  tournamentCategories: storeState.tournamentCategory.entities,
  tournamentCategoriesLoading: storeState.tournamentCategory.loading,
  playerCategories: storeState.playerCategory.entities,
  playerCategoriesLoading: storeState.playerCategory.loading,
  chartTemplates: storeState.chartTemplate.entities,
  chartTemplatesLoading: storeState.chartTemplate.loading,
  disciplines: storeState.discipline.entities,
  disciplinesLoading: storeState.discipline.loading,
  venues: storeState.venue.entities,
  venuesLoading: storeState.venue.loading,
  events: storeState.event.entities,
  eventsLoading: storeState.event.loading,
  // overallRankings: storeState.overallRanking.entities,
  // overallRankingsLoading: storeState.overallRanking.loading,
  rankings: storeState.ranking.entities,
  rankingsLoading: storeState.ranking.loading,
})

const mapDispatchToProps = {
  getTournamentCategory,
  getTournamentCategories,
  getPlayerCategories,
  getChartTemplates,
  getDisciplines,
  getVenues,
  getEvents,
  // getOverallRankings,
  getRankings,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TournamentsUpcomingEditModal))
