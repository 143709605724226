import { IBilliardTable } from '../billiard-table.model'
import { MatchStatus } from '../enumerations/match-status.model'
import { PlaytimeStatus } from './playtime-status.model'

export interface IBilliardTableExtended extends IBilliardTable {
  matchId?: number;
  matchLabel?: string;
  matchStatus?: MatchStatus;
  matchScheduledTime?: string;
  matchStartTime?: string;
  matchDay?: number;
  raceTo?: number;
  aScore?: number;
  aTimeoutUntil?: string;
  aPlaceholder?: boolean;
  bScore?: number;
  bTimeoutUntil?: string;
  bPlaceholder?: boolean;
  shotclockAssigned?: boolean;
  shotclockApproved?: boolean;
  participantAId?: number;
  participantADisplayName?: string;
  participantA2Id?: number;
  participantA2DisplayName?: string;
  participantBId?: number;
  participantBDisplayName?: string;
  participantB2Id?: number;
  participantB2DisplayName?: string;
  roundId?: number;
  roundLabel?: string;
  roundMnemonic?: string;
  playtime?: any;
  prettyPlaytime?: string;
  playtimeStatus?: PlaytimeStatus;
  matchTimeSlotDuration?: any;
}

export const defaultValue: Readonly<IBilliardTableExtended> = {
  playtimeStatus: PlaytimeStatus.NORMAL,
}
