export enum RankingCategory {
  ORA = 'ORA',

  ETM = 'Eurotour Men',

  ETW = 'Eurotour Women',

  EC = 'European Championships',

  WC = 'World Championships',

  YT = 'Youth Tour',
}
