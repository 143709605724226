import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { IAppState } from '../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { getEntity, reset } from '../../../shared/reducers/model/player-profile.reducer'
import { createEntity, updateEntity } from '../../../shared/reducers/model/extended/player-profile.extended.reducer'
import { getEntities as getCountries } from '../../../shared/reducers/model/country.reducer'
import { getEntities as getPlayerCategories } from '../../../shared/reducers/model/player-category.reducer'
import { getEntities as getNationalities } from '../../../shared/reducers/model/nationality.reducer'
import { getEntities as getFederations } from '../../../shared/reducers/model/federation.reducer'

import { Input } from 'antd/es'
import { Button, Col, DatePicker, Divider, Form, message, Row, Select, Space, Switch } from 'antd'
import { GlobalOutlined, MailOutlined } from '@ant-design/icons'

import moment from 'moment'
import TextArea from 'antd/es/input/TextArea'
import Text from 'antd/es/typography/Text'
import Paragraph from 'antd/es/typography/Paragraph'

export interface ITeamProfileProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const TeamProfileUpdate = (props: ITeamProfileProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)
  const { entity, onSetPage, getPageData } = props
  const [submitLoading, setLoadingSubmit] = useState(false)

  const pageData: IPageData = {
    title: `Team Profile - ${!isNew ? `Profile: ${entity.displayName} [${entity.id}]` : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Management',
      },
      {
        title: 'Team Profiles',
        route: 'management/website/team/profile',
      },
      {
        title: !isNew ? `Profile: ${entity.firstName} ${entity.lastName}` : 'Create new Team Profile Entry',
      },
    ],
  }

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id)
    } else {
      props.reset()
    }
    props.getPlayerCategories(0, 99, 'name')
    props.getCountries(0, 999, 'name')
    props.getNationalities(0, 999, 'name')
    props.getFederations(0, 999, 'name')
    onSetPage(pageData)
    // Next Line is preventing Chrome and Firefox autofill/autocomplete feature nightmare
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'stopDamnAutocomplete')
      //you can put any value but NOT "off" or "false" because they DO NOT works
    })
  }, [])

  useEffect(() => {
    if (!props.loading && !isNew && entity?.id) {
      let formValues = {
        ...entity,
        birthday: moment(entity.birthday, 'YYYY-MM-DD'),
      }
      setFieldsValue(formValues)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/app/management/team/profile')
    }
  }, [props.updateSuccess])

  const [availablePlayerCategories, setAvailablePlayerCategories] = useState([])

  useEffect(() => {
    if (!props.playerCategoriesLoading) {
      setAvailablePlayerCategories(props.playerCategories.map(category => ({
        value: category.id,
        label: category.name,
      })))
    }
  }, [props.playerCategoriesLoading])

  const [availableCountries, setAvailableCountries] = useState([])

  useEffect(() => {
    if (!props.countriesLoading) {
      setAvailableCountries(props.countries.map(country => ({
        value: country.id,
        label: country.name,
      })))
    }
  }, [props.countriesLoading])

  const [availableNationalities, setAvailableNationalities] = useState([])

  useEffect(() => {
    if (!props.nationalitiesLoading) {
      setAvailableNationalities(props.nationalities.map(nationality => ({
        value: nationality.id,
        label: nationality.name,
      })))
    }
  }, [props.nationalitiesLoading])

  const [availableFederations, setAvailableFederations] = useState([])

  useEffect(() => {
    if (!props.federationsLoading) {
      setAvailableFederations(props.federations.map(federation => ({
        value: federation.id,
        label: federation.name,
      })))
    }
  }, [props.federationsLoading])

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entityToSubmit = {
          ...entity,
          ...values,
          teamProfile: true,
        }
        if (isNew) {
          props.createEntity(entityToSubmit)
        } else {
          props.updateEntity(entityToSubmit)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  const handleFormOnReset = () => {
    if (!isNew) {
      let formValues = {
        ...entity,
        birthday: moment(entity.birthday, 'YYYY-MM-DD'),
      }
      setFieldsValue(formValues)
    } else
      form.resetFields()
  }

  const handleSendMail = (fieldName: string) => {
    if (fieldName) {
      const value = getFieldValue(fieldName)
      if (value) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          window.location.href = 'mailto:' + value
        } else {
          message.warn('This is not a valid mail address')
        }
      }
    }
  }

  const handleGotoWebsite = (fieldname: string) => {
    if (fieldname) {
      const value = getFieldValue(fieldname)
      if (value) {
        if (/^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(value)) {
          window.open(value, '_blank')
        } else if (/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g.test(value)) {
          window.open('http://' + value, '_blank')
        } else {
          message.warn('This is not a valid website address')

        }
      }
    }
  }

  const onCountryChange = (countryId) => {
    const country = props.countries.find(value => value.id === countryId)
    form.setFieldsValue({
      lastName: country.name,
    })
    onNameChange()
  }

  const onNameChange = () => {
    const lastName = form.getFieldValue('lastName')
    const firstName = form.getFieldValue('firstName')
    if (typeof lastName === 'string' && typeof firstName === 'string')
      form.setFieldsValue({
        lastName: lastName.trim(),
        firstName: firstName.trim(),
        displayName: `${lastName.trim().toUpperCase()}`,
      })
  }

  return (
    <Form form={form} id={'updateTeamProfile'} layout={'horizontal'} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
          autoComplete={'off'}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
          onReset={handleFormOnReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          {!isNew && entity.registrationDate ? (
            <Paragraph style={{ textAlign: 'right' }}>
              <Text>RegistrationDate: {entity.registrationDate}</Text>
            </Paragraph>
          ) : null}
          <Form.Item name="enabled" label="Team Profile active" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} valuePropName="checked">
            <Switch/>
          </Form.Item>
        </Col>
        <Col span={12}/>
        <Col span={12}>
          <Form.Item name="countryId" label="Country" labelCol={{ span: 8 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.countriesLoading}
              options={availableCountries}
              onChange={onCountryChange}
            />
          </Form.Item>
          <Form.Item name="nationalityId" label="Nationality" labelCol={{ span: 8 }}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              loading={props.nationalitiesLoading}
              options={availableNationalities}
            />
          </Form.Item>
          <Form.Item name="representsCountryId" label="Represents" labelCol={{ span: 8 }}
                     rules={[{ required: true, message: 'Represented country is required' }]}
          >
            <Select
                allowClear
                showSearch
                optionFilterProp="label"
                loading={props.countriesLoading}
                options={availableCountries}
            />
          </Form.Item>
          <Form.Item name="playerCategoryId" label="Team Category" labelCol={{ span: 8 }}>
            <Select
              loading={props.playerCategoriesLoading}
              options={availablePlayerCategories}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="lastName" label={'Team name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: true, message: 'Please enter a team name' }, { max: 255 }]}>
            <Input onBlur={onNameChange}/>
          </Form.Item>
          <Form.Item name="displayName" label={'Display Name'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     help="This field automatically updates on name changes"
                     rules={[{ required: true, message: 'Please enter a display name' }, { max: 255 }]}>
            <Input/>
          </Form.Item>
          <Form.Item name="firstName" label={'Firstname'} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                     rules={[{ required: true, message: 'Please enter a firstname' }, { max: 255 }]}
                     hidden initialValue={'Team'}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Space>
            {entity?.id > 0 ? <>
                <Button disabled={true}>Tournament-List</Button>
                <Button disabled={true}>Register for Tournament</Button>
              </>
              : null}
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="reset">
              Reset
            </Button>
            <Link to={'../profile'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entity: storeState.playerProfile.entity,
  loading: storeState.playerProfile.loading,
  updating: storeState.playerProfile.updating,
  updateSuccess: storeState.playerProfile.updateSuccess,
  playerCategories: storeState.playerCategory.entities,
  playerCategoriesLoading: storeState.playerCategory.loading,
  countries: storeState.country.entities,
  countriesLoading: storeState.country.loading,
  nationalities: storeState.nationality.entities,
  nationalitiesLoading: storeState.nationality.loading,
  federations: storeState.federation.entities,
  federationsLoading: storeState.federation.loading,
})

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getCountries,
  getPlayerCategories,
  getNationalities,
  getFederations,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamProfileUpdate))