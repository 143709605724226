export enum EventType {
  ORA = 'ORA',

  EURO_TOUR = 'Euro-Tour',

  EUROPEAN_CHAMPIONSHIP = 'European Championship',

  WORLD_CHAMPIONSHIP = 'World Championship',

  YOUTH_TOUR = 'Youth Tour',
}
